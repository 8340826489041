.user-popup {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    padding: 20px;
    background-color: #232323;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
    border-radius: 6px;
  }
  
  .user-popup__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  .infotooltip__close-button {
    position: absolute;
    right: -40px;
    top: -40px;
    width: 32px;
    height: 32px;
    padding: 0;
    border: none;
    background-color: transparent;
    background-image: url(../../images/close-button.svg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.3s ease-in-out;
  }
  
  .infotooltip__close-button:hover {
    cursor: pointer;
    transform: scale(0.95);
  }

  .guide__copy-button{
    cursor: pointer;
    border-radius: 8px;
    margin: 0;
    margin-top: 8px;
    padding: 10px;
    background-color: blue;
    min-width: 180px;
    align-items: center;
    align-content: center;
    align-self:center;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    transition: all 0.5s ease-in-out;
  }
  
  .guide__copy-button:hover {
    opacity: .8;
  }
  