.buttons-card {
  box-sizing: border-box;
  padding: 50px 0px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 30px;
}

.buttons-nav {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1280px;
  padding: 0 10px;
  margin: 0;
  position: fixed;
  z-index: 1;
  background-color: #232323;
  box-sizing: border-box;
  left: 50%;
  transform: translateX(-50%);
}

.buttons-link {
  display: flex;
  flex-grow: 1;
  margin: 0 auto;
  padding: 10px 0;
  color: #c5c5c5;
  min-width: 25px;
  cursor: pointer;
  justify-content: center;
  font-weight: 900;
  background-color: #161616;
  border: solid 1px #1d1d1d;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.buttons-link:hover {
  background: #1F1F1F;
  color: #2D8CEB;
  border-bottom: solid 1px #2D8CEB;
}

.buttons-link.active {
  background: #1F1F1F;
	color: #2D8CEB;
  border-bottom: solid 1px #2D8CEB;
}

.buttons-card__list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 15px 15px;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.add-button {
  cursor: pointer;
  min-width: 75px;
  height: 75px;
  background-color: white;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  transition: all 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 500;
  font-size: 25px;
}
