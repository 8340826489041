.button-card {
    min-width: 300px;
    min-height: 65px;
    border-radius: 25px;
    background: #1F1F1F;
    border: solid 1px #1d1d1d;
    transition: all 0.5s ease-in-out;
    display: flex;
    align-items: center;
  }

  .button-card:hover{
    background-color: #1d1d1d;
    color: #2d8ceb;
    cursor: pointer;
    border-bottom: solid 1px #2d8ceb;
    fill: #2d8ceb;
    opacity: 1;
  }

  .button-card__main {
    width: 100%;
    padding: 16px 16px 16px;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }

  .button-card__info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .button-card__category {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
  }

  .button-card__item {
    font-size: 12px;
  }

  .button-card__time {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
  }

  .button-card__subscription_end{
    display: flex;
    flex-direction: row;
    column-gap: 7px;
    align-items: center;
  }

  .button-card__title {
    padding: 0;
    margin: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 16px;
    max-width: 290px;
  }

  .button-card__status{
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  .button-card__status_active{
    background-color: #4CAF50;
  }

  .button-card__status_disable{
    background-color: grey;
  }

  .button-card-wrapper {
    position: relative;
    display: inline-block; /* или другое свойство display, в зависимости от вашего дизайна */
  }

  .button-card--disabled {
    opacity: 0.5;
    pointer-events: none; /* Делает элемент неактивным для событий мыши */
  }
